/**
 * Created by @author Søren Tramm on 15/08/2023.
 */
import { UseFormReturn } from 'react-hook-form'
import { LanguageISOCode, LanguageType, Nprs11Question, QuestionType, VIEW_TYPE } from '../../../Types'
import React from 'react'
import { populateConfig } from '../../../util/populateConfig'
import { getFormType } from '../../../components/form/formFactory'
import { radioQuestionConfig } from './config/radioQuestionConfig'
import { checkboxQuestionConfig } from './config/checkboxQuestionConfig'
import { textQuestionConfig } from './config/textQuestionConfig'
import { numberQuestionConfig } from './config/numberQuestionConfig'
import { yesNoQuestionConfig } from './config/yesNoQuestionConfig'
import { getNprs11Point2LabelConfig } from './config/nprs11Point2LabelQuestionConfig'
import { getNprs11Point5LabelConfig } from './config/nprs11Point5LabelQuestionConfig'

export const QuestionTypeFactory = ({
  data,
  formHook,
  viewType,
  supportedLanguages,
  currentLanguage,
  questionType,
  bodyParts,
}: {
  data: Nprs11Question
  formHook: UseFormReturn
  viewType: VIEW_TYPE
  supportedLanguages: LanguageType[]
  currentLanguage: LanguageISOCode
  questionType: QuestionType
  bodyParts: string[]
}) => {
  let config: any = null
  switch (questionType) {
    case 'radio':
      config = radioQuestionConfig
      break
    case 'checkbox':
      config = checkboxQuestionConfig
      break
    case 'text':
      config = textQuestionConfig
      break
    case 'number':
      config = numberQuestionConfig
      break
    case 'info':
      config = null
      break
    case 'nprs11pt5l':
      config = getNprs11Point5LabelConfig(bodyParts)
      break
    case 'nprs11pt2l':
      config = getNprs11Point2LabelConfig(bodyParts)
      break
    case 'yesno':
      config = yesNoQuestionConfig
      break
    default:
      config = null
  }

  if (!config) {
    return null
  }

  const configObj = data ? populateConfig(config, data, viewType) : config?.concat([])
  return (
    <div className="col-12 w-100 py-2">
      {configObj.map((item, index) => {
        return getFormType(item, index, formHook, supportedLanguages, currentLanguage)
      })}
    </div>
  )
}
