/**
 * Created by @author Søren Tramm on 04/09/2023.
 */

const baseConfiguration = {
  id: 'options',
  type: 'TEXT_TRANSLATION',
  placeholder: '',
  label: '',
  postfix: '',
  value: { en: '' },
  validation: { required: true },
  errorMsg: 'Value is required',
}
export const yesNoQuestionConfig = [
  {
    ...baseConfiguration,
    label: '"Yes" option',
    placeholder: 'Yes',
    id: baseConfiguration.id + '.0',
    value: { en: 'Yes' },
  },
  {
    ...baseConfiguration,
    label: '"No" option',
    placeholder: 'No',
    id: baseConfiguration.id + '.1',
    value: { en: 'No' },
  },
] as const
