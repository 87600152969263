/**
 * Created by @author Søren Tramm on 15/08/2023.
 */
export const checkboxQuestionConfig = [
  {
    id: 'options',
    type: 'DYNAMIC_OPTIONS_TRANSLATION',
    placeholder: 'Fill in option here',
    label: 'Options',
    postfix: '',
    value: [],
    validation: { required: true },
    errorMsgField: 'Please fill in text for following countries',
    errorMsgGeneral: 'Please add some options',
  },
] as const
