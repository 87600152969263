export const getSiteConfig = (timeZones) => {
  const siteConfig = [
    {
      id: 'siteNumber',
      type: 'TEXT',
      placeholder: 'Add site number',
      label: 'Site number',
      postfix: '',
      value: '',
      validation: { required: true },
      errorMsg: 'Site number is required',
      disabled: true,
    },
    {
      id: 'title',
      type: 'TEXT',
      placeholder: 'Add site title',
      label: 'Site title',
      postfix: '',
      value: '',
      validation: { required: true },
      errorMsg: 'Site title is required',
      disabled: true,
    },
    {
      id: 'address',
      type: 'TEXT',
      placeholder: 'Add site address',
      label: 'Site address',
      postfix: '',
      value: '',
      validation: { required: true },
      errorMsg: 'Site address is required',
      disabled: true,
    },
    {
      id: 'phone',
      type: 'PHONE',
      placeholder: 'Add site phone number',
      label: 'Site phone number',
      postfix: '',
      value: '',
      validation: {
        validate: (value) => {
          if (!value.tel && !value.countryCode) {
            return 'Country code and phone number are required.'
          }

          if (!value.tel) {
            return 'Phone number is required.'
          }
          if (!value.countryCode) {
            return 'Country code is required.'
          }

          return value || 'This is required.'
        },
      },
      errorMsg: 'Site phone number is required',
      disabled: true,
    },
    {
      id: 'contactPersonName',
      type: 'TEXT',
      placeholder: 'Add name for site contact person',
      label: 'Site contact person name',
      postfix: '',
      value: '',
      validation: { required: true },
      errorMsg: 'Site contact person is required',
      disabled: true,
    },
    {
      id: 'contactPersonJobTitle',
      type: 'TEXT',
      placeholder: 'Add job title for site contact person',
      label: 'Site contact person job title',
      postfix: '',
      value: '',
      validation: { required: true },
      errorMsg: 'Site contact person is required',
      disabled: true,
    },
    {
      id: 'contactPersonEmail',
      type: 'TEXT',
      placeholder: 'Add email for site contact person ',
      label: 'Site contact person email',
      postfix: '',
      value: '',
      validation: { required: true },
      errorMsg: 'Site contact person is required',
      disabled: true,
    },
    {
      id: 'timeZone',
      type: 'DROPDOWN',
      placeholder: 'Select a time zone',
      label: 'Time zone',
      postfix: '',
      value: '',
      options: timeZones,
      validation: { required: true },
      errorMsg: 'A time zone must be selected',
      disabled: false,
    },
    {
      id: 'active',
      type: 'SWITCH',
      placeholder: 'Site activation status',
      label: 'Site activation status',
      postfix: '',
      value: '',
      validation: { required: false },
    },
  ] as const

  return siteConfig
}
