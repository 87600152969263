import {
  DateFormConfigObject,
  DropdownFormConfigObject,
  DynamicOptionsFormConfigObject,
  DynamicOptionsFormConfigObjectTranslation,
  FormConfigTypes,
  LanguageISOCode,
  MultipleInputFormConfigObject,
  NumberFormConfigObject,
  PhoneFormConfigObject,
  ReminderTimeWidgetConfigObject,
  SectionFormConfigObject,
  SelectMultipleFormConfigObject,
  SliderFormConfigObject,
  SliderRangeFormConfigObject,
  SpacerFormConfigObject,
  SupportedCountriesType,
  SwitchFormConfigObject,
  TextAreaFormConfigObject,
  TextAreaFormConfigObjectTranslation,
  TextFormConfigObject,
  TextFormConfigObjectTranslation,
  TimeFormConfigObject,
  VIEW_TYPE,
  WindowWidgetConfigObject,
} from '../../Types'

import { FormDateRange } from './FormDateRange'
import { FormDropDown } from './FormDropDown'
import { FormDynamicOptions } from './FormDynamicOptions'
import { FormDynamicOptionsTranslation } from './FormDynamicOptionsTranslation'
import { FormInputMultiple } from './FormInputMultiple'
import { FormNumber } from './FormNumber'
import { FormPhoneNumber } from './FormPhoneNumber'
import { FormSelectMultiple } from './FormSelectMultiple'
import { FormSlider } from './FormSlider'
import { FormSliderRange } from './FormSliderRange'

import { FormTextArea } from './FormTextArea'
import { FormTextAreaTranslation } from './FormTextAreaTranslation'
import { FormTextField } from './FormTextField'
import { FormTextFieldTranslation } from './FormTextFieldTranslation'
import { FormTime } from './FormTime'
import { ReminderTimeWidget } from '../../pages/questionnaire/ReminderTimeWidget'
import React from 'react'
import { WindowWidget } from '../../pages/questionnaire/WindowWidget'
import { FormSwitch } from './FormSwitch'
import { FormViewItem } from './FormViewItem'

export const getFormType = (
  formConfigObj: FormConfigTypes,
  index: number,
  formHook: any,
  languages?: any[],
  currentLanguage?: LanguageISOCode,
  supportedCountries: SupportedCountriesType[] = [],
  viewType: VIEW_TYPE = 'CREATE'
) => {
  const type = formConfigObj.type

  const copy = { ...formConfigObj }

  switch (type) {
    case 'TEXT':
      return (
        <FormTextField
          key={index}
          viewType={viewType}
          data={copy as TextFormConfigObject}
          formHook={formHook}
        ></FormTextField>
      )

    case 'DROPDOWN':
      return (
        <FormDropDown
          key={index + '-' + formConfigObj.value}
          data={formConfigObj as DropdownFormConfigObject}
          formHook={formHook}
        ></FormDropDown>
      )

    case 'SWITCH':
      return <FormSwitch key={index} data={formConfigObj as SwitchFormConfigObject} formHook={formHook}></FormSwitch>

    case 'NUMBER':
      return <FormNumber key={index} data={formConfigObj as NumberFormConfigObject} formHook={formHook}></FormNumber>

    case 'SLIDER_RANGE':
      return (
        <FormSliderRange
          key={index}
          data={formConfigObj as SliderRangeFormConfigObject}
          formHook={formHook}
        ></FormSliderRange>
      )

    case 'SLIDER':
      return <FormSlider key={index} data={formConfigObj as SliderFormConfigObject} formHook={formHook}></FormSlider>

    case 'SELECT_MULTIPLE':
      return (
        <FormSelectMultiple
          key={index}
          data={formConfigObj as SelectMultipleFormConfigObject}
          formHook={formHook}
        ></FormSelectMultiple>
      )

    case 'PHONE':
      return (
        <FormPhoneNumber
          key={index}
          data={formConfigObj as PhoneFormConfigObject}
          formHook={formHook}
          viewType={viewType}
          supportedCountries={supportedCountries}
        ></FormPhoneNumber>
      )

    case 'TIME':
      return <FormTime key={index} data={formConfigObj as TimeFormConfigObject} formHook={formHook}></FormTime>

    case 'DATE_RANGE':
      return <FormDateRange key={index} data={formConfigObj as DateFormConfigObject} formHook={formHook} />

    case 'TEXTAREA':
      return (
        <FormTextArea key={index} data={formConfigObj as TextAreaFormConfigObject} formHook={formHook}></FormTextArea>
      )

    case 'DYNAMIC_OPTIONS':
      return (
        <FormDynamicOptions
          key={index}
          data={formConfigObj as DynamicOptionsFormConfigObject}
          formHook={formHook}
        ></FormDynamicOptions>
      )

    case 'INPUT_MULTIPLE':
      return (
        <FormInputMultiple
          key={index}
          data={formConfigObj as MultipleInputFormConfigObject}
          formHook={formHook}
        ></FormInputMultiple>
      )

    case 'TEXT_TRANSLATION':
      return (
        <FormTextFieldTranslation
          key={index}
          data={copy as TextFormConfigObjectTranslation}
          formHook={formHook}
          languages={languages}
          currentLanguage={currentLanguage}
        />
      )

    case 'TEXTAREA_TRANSLATION':
      return (
        <FormTextAreaTranslation
          key={index}
          data={formConfigObj as TextAreaFormConfigObjectTranslation}
          formHook={formHook}
          languages={languages}
          currentLanguage={currentLanguage}
        />
      )

    case 'DYNAMIC_OPTIONS_TRANSLATION':
      return (
        <FormDynamicOptionsTranslation
          key={index}
          data={formConfigObj as DynamicOptionsFormConfigObjectTranslation}
          formHook={formHook}
          languages={languages}
          currentLanguage={currentLanguage}
        />
      )

    case 'REMINDER_TIME':
      return (
        <ReminderTimeWidget key={index} data={formConfigObj as ReminderTimeWidgetConfigObject} formHook={formHook} />
      )

    case 'WINDOW':
      return <WindowWidget key={index} data={formConfigObj as WindowWidgetConfigObject} formHook={formHook} />

    case 'SPACER':
      return <FormViewItem key={index} item={formConfigObj as SpacerFormConfigObject}></FormViewItem>

    case 'SECTION':
      return (
        <div key={index} className="row" style={{}}>
          <div className="col-12 mt-2">
            <p className="">
              {(formConfigObj as SectionFormConfigObject).label}{' '}
              <span className="opacity-75">{(formConfigObj as SectionFormConfigObject).postfix}</span>
            </p>
            {formConfigObj.value ? (
              <p className="opacity-75 mb-2">{(formConfigObj as SectionFormConfigObject).value}</p>
            ) : null}
          </div>
        </div>
      )

    default:
      return (
        <div key={Math.random() * 10} className="">
          WHAT
        </div>
      )
  }
}
