/**
 * Created by @author Søren Tramm on 15/08/2023.
 */
import { QuestionValueLabelType } from '../../../../Types'

export const getCommonQuestionConfig = (questionTypes: QuestionValueLabelType[]) => {
  return [
    {
      id: 'type',
      type: 'DROPDOWN',
      placeholder: 'Select a question type',
      label: 'Type',
      postfix: '',
      value: '',
      options: questionTypes, //.map((item: QuestionType, index) => ({ value: item, label: QuestionsTypeLabels[item] })),
      validation: { required: true },
      errorMsg: 'A type must be selected',
    },
    {
      id: 'title',
      type: 'TEXT_TRANSLATION',
      placeholder: 'Add title',
      label: 'Title',
      postfix: '',
      value: 'Title',
      validation: { required: true },
      errorMsg: 'Title is required',
    },
    {
      id: 'info',
      type: 'TEXT_TRANSLATION',
      placeholder: 'Add info',
      label: 'Info (tool tip)',
      postfix: '',
      value: '',
      validation: { required: false },
    },
    {
      id: 'body',
      type: 'TEXTAREA_TRANSLATION',
      placeholder: 'Add body text',
      label: 'Main text',
      postfix: '',
      value: 'Main text',
      validation: { required: true },
      errorMsg: 'You need to add some text',
    },
    {
      id: 'continuation',
      type: 'TEXT_TRANSLATION',
      placeholder: 'Next, Continue etc',
      label: 'Label text for continue button',
      postfix: '',
      value: 'Continue',
      validation: { required: true },
      errorMsg: 'You need to add a button label',
    },
    {
      id: 'errormessage',
      type: 'TEXT_TRANSLATION',
      placeholder: 'Something went wrong',
      label: 'General error message',
      postfix: '',
      value: '',
      validation: { required: false },
    },
    {
      id: 'required',
      type: 'SWITCH',
      placeholder: '',
      label: 'Required',
      postfix: '',
      value: true,
      validation: { required: false },
      errorMsg: '',
    },
  ] as const
}
