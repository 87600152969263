import React, { useEffect, useState } from 'react'

import { InputNumber, Select } from 'antd'
import { UseFormReturn } from 'react-hook-form'
import { FormLabel } from './FormLabel'
import { CRUD_VIEW_TYPE, PhoneFormConfigObject, SupportedCountriesType, VIEW_TYPE } from '../../Types'

const { Option } = Select

type Props = {
  formHook: UseFormReturn
  data: PhoneFormConfigObject
  viewType: VIEW_TYPE
  supportedCountries: SupportedCountriesType[]
}

const countryKey = 'countryCode'
const telKey = 'tel'

export const FormPhoneNumber = ({ data, formHook, viewType, supportedCountries }: Props) => {
  const {
    setValue,
    register,
    formState: { errors },
    clearErrors,
    getValues,
  } = formHook

  const formValue = getValues(data.id)
  const currentValue = !formValue ? data.value : formValue

  const [countryCodeState, setCountryCodeState] = useState<any>(
    currentValue[countryKey] ? currentValue[countryKey] : ''
  )
  const [telState, setTelState] = useState<any>(currentValue[telKey] ? currentValue[telKey] : '')

  const onSelect = (v, k) => {
    clearErrors(countryCodeState)
    setCountryCodeState(k.value)
    setValue(data.id, { [countryKey]: k.value, [telKey]: telState })
  }

  const numberChange = (k: string | null) => {
    if (k && k.length !== 0) {
      clearErrors(telState)
    }

    console.log('FormPhoneNumber > k = ', k)
    if (k !== null) {
      const num = parseInt(k)

      setTelState(num)
      setValue(data.id, { [countryKey]: countryCodeState, [telKey]: num })
    } else {
      setTelState('')
      setValue(data.id, { [countryKey]: countryCodeState, [telKey]: '' })
    }
  }

  useEffect(() => {
    register(data.id, data.validation)
    setValue(data.id, currentValue ? currentValue : { [countryKey]: '', [telKey]: '' })
  }, [register, data.id, data.validation, setValue, data.value, currentValue])

  const errorState = errors[data.id]
  const countryErrorState = errors[countryKey]

  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)
  const countryErrorMessage: any =
    countryErrorState && countryErrorState.message ? countryErrorState.message : 'Missing country code'

  const selectBefore = (
    <Select
      value={currentValue?.countryCode || ''}
      optionLabelProp="label"
      onSelect={onSelect}
      popupMatchSelectWidth={400}
      style={{ width: 95 }}
      disabled={currentValue && viewType === CRUD_VIEW_TYPE.UPDATE ? true : false}
    >
      {supportedCountries.map((item, index) => {
        return (
          <Option key={index} value={item.countryCode}>
            <p>
              {item.countryCode} <span className="opacity-75">{item.name}</span>
            </p>
          </Option>
        )
      })}
    </Select>
  )

  return (
    <div className="w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <InputNumber
        className="w-100"
        addonBefore={selectBefore}
        onChange={numberChange}
        disabled={currentValue && viewType === CRUD_VIEW_TYPE.UPDATE ? true : false}
        value={currentValue?.tel || ''}
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
      {countryErrorState ? <div className="p-small text-danger">{countryErrorMessage}</div> : null}
    </div>
  )
}
