/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/04/2022.
 */
import React, { useEffect, useState } from 'react'
import { Switch } from 'antd'
import { FormLabel } from './FormLabel'
import { UseFormReturn } from 'react-hook-form'
import { SwitchFormConfigObject } from '../../Types'

type Props = { formHook: UseFormReturn; data: SwitchFormConfigObject }
export const FormSwitch = ({ data, formHook }: Props) => {
  //const { control } = formHook

  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  const currentValue = getValues(data.id) ? getValues(data.id) : data.value

  const [state, setState] = useState<boolean>(currentValue)

  useEffect(() => {
    register(data.id, data.validation)
  }, [register, data.id, data.validation])

  useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    } else {
      setValue(data.id, false)
    }
  }, [data.value, data.id, data.validation, setValue])

  useEffect(() => {
    return () => unregister(data.id)
  }, [unregister, data.id])

  const onChange = (checked: boolean) => {
    setValue(data.id, checked)
    setState(checked)
  }

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      <Switch className="mt-1" checked={state} onChange={onChange} disabled={data?.disabled || false} />

      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
