import { PROJECT_CONDITION_ENUM } from '../../Types'
import { LANGUAGE_LABELS } from '../../constants'

export const getProject = (supportedCountries, supportedLanguages) => {
  const projectConfig = [
    {
      id: 'title',
      type: 'TEXT',
      placeholder: 'Give your project a title',
      label: 'Project Title *',
      postfix: '',
      defaultValue: '',
      value: '',
      className: 'col-12',
      validation: { required: true },
      errorMsg: 'Project title is required',
    },

    {
      id: 'studyId',
      type: 'TEXT',
      placeholder: 'Add study ID',
      label: 'Study ID (no special characters or spaces) *',
      postfix: '',
      defaultValue: '',
      value: '',
      className: 'col-12',
      validation: { required: true, minLength: 3, pattern: /^[-a-zA-Z0-9]*$/ },
      errorMsg: 'Study ID is required, must at least 3 characters and cannot contain special characters or spaces',
    },

    {
      id: 'displayName',
      type: 'TEXT',
      placeholder: 'Add display name',
      label: 'Display Name (readable name for your project)',
      postfix: '',
      defaultValue: '',
      value: '',
      className: 'col-12',
      validation: { required: true },
      errorMsg: 'Display Name is required.',
    },

    {
      id: 'sponsor',
      type: 'TEXT',
      placeholder: 'Add Sponsor',
      label: 'Sponsor *',
      postfix: '',
      defaultValue: '',
      value: '',
      className: 'col-12',
      validation: { required: true },
      errorMsg: 'Sponsor is required',
    },
    {
      id: 'cro',
      type: 'TEXT',
      placeholder: 'Add CRO',
      label: 'CRO *',
      postfix: '',
      defaultValue: '',
      value: '',
      className: 'col-12',
      validation: { required: true },
      errorMsg: 'CRO is required',
    },
    {
      id: 'country',
      type: 'SELECT_MULTIPLE',
      placeholder: 'Select country/countries',
      label: 'Country/Countries *',
      postfix: '',
      value: '',
      className: 'col-12',
      validation: { required: true },
      errorMsg: 'A country is required',
      options: getCountries(supportedCountries),
    },
    {
      id: 'languages',
      type: 'SELECT_MULTIPLE',
      placeholder: 'Select language/languages',
      label: 'Language/Languages *',
      postfix: '',
      value: '',
      className: 'col-12',
      validation: {
        required: true,
      },
      errorMsg: 'A language must be selected',
      options: getLanguages(supportedLanguages),
    },
    {
      id: 'condition',
      type: 'DROPDOWN',
      placeholder: 'Select a condition',
      label: 'Condition *',
      options: [
        { value: PROJECT_CONDITION_ENUM.ECZCEMA, label: 'Eczcema' },
        { value: PROJECT_CONDITION_ENUM.OSTEOARTHRITIS, label: 'Osteoarthritis' },
        { value: PROJECT_CONDITION_ENUM.PSORIASIS, label: 'Psoriasis' },
        { value: PROJECT_CONDITION_ENUM.ALZHEIMER, label: 'Alzheimer' },
        { value: PROJECT_CONDITION_ENUM.ACNE, label: 'Acne' },
        { value: PROJECT_CONDITION_ENUM.ACTINIC_KERATOSIS, label: 'Actinic keratosis' },
      ],
      postfix: '',
      className: 'col-12',
      validation: { required: true },
      errorMsg: 'A condition must be selected',
      value: '',
    },
    {
      id: 'training',
      type: 'NUMBER',
      placeholder: 'Input your start version',
      label: 'Training version *',
      postfix: '',
      value: '',
      className: 'col-12',
      validation: {
        required: true,
        pattern: {
          value: /^[0-9]+$/,
          message: 'Please enter a number',
        },
      },
      errorMsg: 'Version number must be set',
    },
  ]

  return projectConfig
}

export const getProjectViewConfig = () => {
  return projectViewConfig.concat([])
}

const getCountries = (supportedCountries) => {
  return supportedCountries.map((country) => {
    return { value: country.abbreviation, label: country.name }
  })
}

const getLanguages = (supportedLanguages) => {
  return supportedLanguages.map((language) => {
    return { value: language.isoCode, label: LANGUAGE_LABELS[language.isoCode] }
  })
}

const projectViewConfig = [
  {
    id: 'title',
    type: 'TEXT',
    placeholder: 'Give your project a title',
    label: 'Title *',
    postfix: '',
    defaultValue: '',
    value: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'Project title is required',
  },
  {
    id: 'studyId',
    type: 'TEXT',
    placeholder: 'Add study ID',
    label: 'Study ID *',
    postfix: '',
    defaultValue: '',
    value: '',
    className: 'col-12',
    validation: { required: true, minLength: 3, pattern: /^[-a-zA-Z0-9]*$/ },
    errorMsg: 'Study ID is required, must be at least 3 characters and cannot contain special characters or spaces',
  },
  {
    id: 'displayName',
    type: 'TEXT',
    placeholder: 'Display name',
    label: 'Display Name',
    postfix: '',
    defaultValue: '',
    value: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'Display Name is required.',
  },

  // {
  //   id: 'protocolId',
  //   type: 'TEXT',
  //   placeholder: 'Add protocol ID',
  //   label: 'Protocol ID',
  //   postfix: '',
  //   defaultValue: '',
  //   value: '',
  //   className: 'col-12',
  //   validation: { required: true },
  //   errorMsg: 'Protocol ID is required',
  // },
  {
    id: 'sponsor',
    type: 'TEXT',
    placeholder: 'Add Sponsor',
    label: 'Sponsor *',
    postfix: '',
    defaultValue: '',
    value: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'Sponsor is required',
  },
  {
    id: 'cro',
    type: 'TEXT',
    placeholder: 'Add CRO',
    label: 'CRO *',
    postfix: '',
    defaultValue: '',
    value: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'CRO is required',
  },
  {
    id: 'condition',
    type: 'DROPDOWN',
    placeholder: 'Select a condition',
    label: 'Condition *',
    options: [
      { value: PROJECT_CONDITION_ENUM.ECZCEMA, label: 'Eczcema' },
      { value: PROJECT_CONDITION_ENUM.OSTEOARTHRITIS, label: 'Osteoarthritis' },
      { value: PROJECT_CONDITION_ENUM.PSORIASIS, label: 'Psoriasis' },
      { value: PROJECT_CONDITION_ENUM.ALZHEIMER, label: 'Alzheimer' },
      { value: PROJECT_CONDITION_ENUM.ACNE, label: 'Acne' },
      { value: PROJECT_CONDITION_ENUM.ACTINIC_KERATOSIS, label: 'Actinic keratosis' },
    ],
    postfix: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'A condition must be selected',
    value: '',
  },
  {
    id: 'training',
    type: 'NUMBER',
    placeholder: 'Input your start version',
    label: 'Training version *',
    postfix: '',
    value: 0,
    className: 'col-12',
    validation: {
      required: true,
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter a number',
      },
    },
    errorMsg: 'A training version must be set',
  },
]

export const newUserConfig = [
  {
    id: 'name',
    type: 'TEXT',
    placeholder: 'Add user name',
    label: 'Name *',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },
  {
    id: 'email',
    type: 'TEXT',
    placeholder: 'Add user email',
    label: 'Email *',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Email required',
  },
  // {
  //   id: 'phone',
  //   type: 'TEXT',
  //   placeholder: 'Add investigator phone number',
  //   label: 'Phone number',
  //   postfix: '',
  //   value: '',
  //   validation: { required: true },
  //   errorMsg: 'Investigator phone number required',
  // },
]
