/**
 * Created by @author Søren Tramm on 21/08/2023.
 */
import { BODY_PART_LABELS } from '../../../../constants'

const baseConfigurationClassification = {
  id: 'options.classification',
  type: 'TEXT_TRANSLATION',
  placeholder: 'Classification',
  label: '',
  postfix: '',
  value: { en: '' },
  validation: { required: true },
  errorMsg: 'Value is required',
}
const baseConfigurationIntensity = {
  id: 'options.intensity',
  type: 'TEXT_TRANSLATION',
  placeholder: 'Intensity',
  label: '',
  postfix: '',
  value: { en: '' },
  validation: { required: true },
  errorMsg: 'Value is required',
}
const nprs11Point5LabelQuestionConfigBase = [
  {
    ...baseConfigurationClassification,
    label: 'Classification labels',
    id: baseConfigurationClassification.id + '.0',
    value: 'None',
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.1',
    value: 'Mild',
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.2',
    value: 'Moderate',
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.3',
    value: 'Severe',
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.4',
    value: 'Worst pain imaginable',
  },
  {
    ...baseConfigurationIntensity,
    label: 'Intensity values',
    id: baseConfigurationIntensity.id + '.0',
    value: 0,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.1',
    value: 1,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.2',
    value: 2,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.3',
    value: 3,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.4',
    value: 4,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.5',
    value: 5,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.6',
    value: 6,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.7',
    value: 7,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.8',
    value: 8,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.9',
    value: 9,
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.10',
    value: 10,
  },
] as const

const getBodyPartLabel = (value: string) => {
  return BODY_PART_LABELS[value] ?? 'Missing label'
}

export const getNprs11Point5LabelConfig = (bodyParts: string[]) => {
  return [
    {
      id: 'correlations.bodypart',
      type: 'DROPDOWN',
      placeholder: 'Select a body part',
      label: 'What part of the body does this question relate to? ',
      postfix: '',
      value: '',
      options: bodyParts?.map((value) => {
        return { value, label: getBodyPartLabel(value) }
      }),
      validation: { required: true },
      errorMsg: 'Body part is required',
      disabled: false,
    },
    ...nprs11Point5LabelQuestionConfigBase,
  ] as const
}
