import { PHASE_TYPE_ENUM } from '../../Types'
import { BODY_PART_LABELS } from '../../constants'

/**
 * Updates the phase configuration array based on the provided body parts data.
 * @param {string[]} bodyParts - An array of body parts data to update the configuration.
 * @param {string[]} labels - An array of body parts data to update the configuration.
 * @returns {{ value: string; label: string }[]} - The updated phase configuration array with modified options.
 */
export const getPhaseConfig = (bodyParts: string[]) => {
  return phaseConfig.map((obj) => {
    if (obj?.id === 'bodyParts') {
      return { ...obj, options: createBodyPartOptions(bodyParts) }
    }
    return obj
  })
}

const createBodyPartOptions = (bodyParts: string[]) => {
  return bodyParts.map((value) => {
    const label: string = BODY_PART_LABELS[value] ?? 'Missing label'
    return { value, label: label }
  })
}

const phaseConfig = [
  {
    id: 'title',
    type: 'TEXT',
    placeholder: 'Add title',
    label: 'Title',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Title is required',
    disabled: true,
  },
  {
    id: 'duration',
    type: 'NUMBER',
    placeholder: 'Add duration',
    label: 'Duration in days',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'duration is required',
  },
  {
    id: 'type',
    type: 'DROPDOWN',
    placeholder: 'Select a type',
    label: 'Phase type',
    postfix: '',
    value: '',
    options: [
      { value: PHASE_TYPE_ENUM.IN_SCREENING, label: 'In screening' },
      { value: PHASE_TYPE_ENUM.IN_STUDY, label: 'In study' },
    ],
    validation: { required: true },
    errorMsg: 'A type must be selected',
    disabled: false,
  },
  {
    id: 'bodyParts',
    type: 'SELECT_MULTIPLE',
    placeholder: 'Select body parts',
    label: 'Body parts',
    postfix: '',
    value: '',
    className: 'col-12',
    validation: { required: false },
    options: [{}], // Is updated in getPhaseConfig
  },
  {
    id: 'shortCompliance',
    type: 'NUMBER',
    placeholder: 'Add short compliance trigger',
    label: 'Short compliance in days',
    postfix: '',
    value: 20,
    validation: { required: true },
    errorMsg: 'Compliance is required',
  },
  {
    id: 'overallCompliance',
    type: 'NUMBER',
    placeholder: 'Add overall compliance trigger',
    label: 'Overall compliance in %',
    postfix: '',
    value: 80,
    validation: { required: true },
    errorMsg: 'Compliance is required',
  },
]
