/**
 * Created by @author Søren Tramm
 */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { HTTPMethod } from '../../Types'
import { openNotification } from '../../components/notifications/openNotification'
import { Alert, Button, Popconfirm } from 'antd'
import { PhaseView } from './PhaseView'
import { usePhaseById } from '../../hooks/usePhaseById'
import { useSWRConfig } from 'swr'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
}

export const PhaseClone = ({ closeDrawer, onClose }: Props) => {
  const { projectId, phaseId, protocolId } = useParams() as { projectId: string; phaseId: string; protocolId: string }
  const { mutate: globalMutate } = useSWRConfig()
  const [errorState, setErrorState] = useState<any>()
  const { data, mutate } = usePhaseById(projectId, phaseId)

  const onSubmit = () => {
    const sendData = { phaseId, protocolId }

    return post(ENDPOINTS.PROTOCOLS.ACTIONS.CLONE_PHASE(projectId), sendData, HTTPMethod.POST)
      .then((result) => {
        openNotification('Phase cloned', '', 'happy')

        mutate()
        globalMutate(ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId))

        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <PhaseView data={data}>
        <div className="col-12 d-flex justify-content-end">
          <Button className="me-2" type="default" onClick={navigateBack}>
            Cancel
          </Button>

          <Popconfirm
            placement="left"
            title={<p className="pe-4">Please confirm your action</p>}
            description={<p className="pe-4">Do you want to continue with cloning this phase</p>}
            onConfirm={onSubmit}
            okText={<p className="px-2">Yes</p>}
            cancelText="No"
          >
            <Button type="primary">Clone</Button>
          </Popconfirm>
        </div>
      </PhaseView>
      <div className="col-12 mt-3">
        {errorState ? (
          <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
