import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const usePhaseById = (projectId: string, phaseId: string) => {
  const p = ENDPOINTS.PHASES.byPhaseId(projectId, phaseId)

  const { data, mutate, error } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
