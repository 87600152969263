export const getClinician = () => {
  return clinicianConfig
}

const clinicianConfig = [
  {
    id: 'name',
    type: 'TEXT',
    placeholder: 'Add user name',
    label: 'Name *',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },
  {
    id: 'email',
    type: 'TEXT',
    placeholder: 'Add user email',
    label: 'Email *',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Email required',
    disabled: true,
  },

  {
    id: 'phone',
    type: 'PHONE',
    placeholder: 'Add investigator phone number',
    label: 'Phone number * (All clinicians receive an authentication code by SMS when logging into the Portal)',
    postfix: '',
    value: '',
    validation: {
      validate: (value) => {
        console.log(' clinicianConfig > value = ', value)

        if (!value.tel && !value.countryCode) {
          return 'This is required.'
        }

        if (!value.tel) {
          return 'Phone number is required.'
        }
        if (!value.countryCode) {
          return 'Country code is required.'
        }

        return value || 'This is required.'
      },
    },
    errorMsg: 'Phone number required',
    disabled: false,
  },
  {
    id: 'jobTitle',
    type: 'TEXT',
    placeholder: 'Add job title (optional)',
    label: 'Job title',
    postfix: '',
    value: '',
    validation: { required: false },
  },
]
