/**
 * Created by @author Søren Tramm on 08/08/2023.
 */
import { CRUD_VIEW_TYPE, HTTPMethod, LanguageISOCode, VIEW_TYPE } from '../../../Types'
import React, { useState } from 'react'
import { Alert, Button } from 'antd'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../../service/ENDPOINTS'
import { post } from '../../../service/API'
import { QuestionCommon } from './QuestionCommon'
import { openNotification } from '../../../components/notifications/openNotification'
import { mutate as mutateGlobal } from 'swr'
import { useProject } from '../../../hooks/useProject'
import { QuestionTypeFactory } from './QuestionTypeFactory'
import { LanguageDropdown } from './LanguageDropdown'
import { useByEndpoint } from '../../../hooks/useByEndpoint'
import { useBodyPartsQuestion } from '../../../hooks/useBodyPartsQuestion'
import { useProjectConfig } from '../../../hooks/useProjectConfig'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}
export const QuestionCRUD = ({ closeDrawer, onClose, viewType = CRUD_VIEW_TYPE.CREATE }: Props) => {
  const { projectId, phaseId, questionnaireId, questionId } = useParams() as {
    projectId: string
    phaseId: string
    questionnaireId: string
    questionId: string
  }

  const { data: bodyParts } = useBodyPartsQuestion(projectId, phaseId)

  const formHook = useForm()
  const { watch } = formHook
  const questionType = watch('type', '')

  const params: any = useParams()
  const { data: projectData } = useProject(params.projectId || '')
  const supportedLanguages = projectData.languages
  const defaultLanguage = supportedLanguages[0]
  const [currentLanguage, setCurrentLanguage] = useState<LanguageISOCode>(defaultLanguage.isoCode)

  const p = questionId ? ENDPOINTS.QUESTIONS.byQuestionId(projectId, questionId) : ''
  const { data, mutate } = useByEndpoint(p)

  const { data: projectConfigData } = useProjectConfig()

  const {
    handleSubmit,
    formState: { errors },
  } = formHook
  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()

    const createData = { ...formData, questionnaireId: questionnaireId }
    const updatedData = { ...formData, _id: questionId, order: data ? data.order : null }

    const method = viewType === 'UPDATE' ? HTTPMethod.PUT : HTTPMethod.POST
    const endpoint =
      viewType === 'UPDATE' ? ENDPOINTS.QUESTIONS.update(projectId, questionId) : ENDPOINTS.QUESTIONS.create(projectId)
    const sendData = viewType === 'UPDATE' ? updatedData : createData

    console.log('sendData: ', sendData)
    // console.log('errors: ', errors)

    return post(endpoint, sendData, method)
      .then(() => {
        openNotification(viewType === 'UPDATE' ? 'Question has been updated' : 'Question created ', '', 'happy')
        mutate() // Mutate for revalidate data in this component

        // Mutate for revalidate in list of questions in QuestionManagerDrawer
        const key = ENDPOINTS.QUESTIONS.byQuestionnaireId(projectId, questionnaireId)
        mutateGlobal(key)

        if (onClose) {
          onClose()
        }

        if (closeDrawer && viewType !== CRUD_VIEW_TYPE.VIEW) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    console.log('onError = ', err, errors)
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
    //closeDrawer(true)
  }

  return (
    <>
      {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
        <>
          <LanguageDropdown
            supportedLanguages={supportedLanguages}
            defaultLanguage={defaultLanguage}
            onChange={setCurrentLanguage}
          />
          <div className={'mt-2 d-flex justify-content-center'}>
            <hr
              style={{
                background: 'none',
                borderTop: '1px dashed #999999',
                height: '1px',
                width: '100%',
              }}
            />
          </div>
          <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
            <QuestionCommon
              data={data}
              formHook={formHook}
              viewType={viewType}
              languageCodes={projectData.languages}
              currentLanguage={currentLanguage ? currentLanguage : defaultLanguage}
              questionTypes={projectConfigData?.questionTypes || []}
            />
            <QuestionTypeFactory
              data={data}
              formHook={formHook}
              viewType={viewType}
              questionType={questionType}
              supportedLanguages={projectData.languages}
              currentLanguage={currentLanguage ? currentLanguage : defaultLanguage}
              bodyParts={bodyParts}
            />
            <div className="col-12 mt-3">
              {errorState ? (
                <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
              ) : (
                <></>
              )}
            </div>
            <div className="col-12 d-flex justify-content-end mt-3">
              <Button className="me-2" onClick={navigateBack}>
                Cancel
              </Button>
              <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
                {viewType === 'CREATE' ? 'Save Question' : 'Save Changes'}
              </Button>
            </div>
          </form>
        </>
      ) : (
        <div>Update</div>
      )}
    </>
  )
}
