import { FieldValues, Validate } from 'react-hook-form'

export type user = {
  name: string
}

export type AuthEventResponseType = {
  //https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_AdminListUserAuthEvents.html
  ChallengeResponses: [
    {
      ChallengeName: string
      ChallengeResponse: string
    }
  ]
  CreationDate: number
  EventContextData: {
    City: string
    Country: string
    DeviceName: string
    IpAddress: string
    Timezone: string
  }
  EventFeedback: {
    FeedbackDate: number
    FeedbackValue: string
    Provider: string
  }
  EventId: string
  EventResponse: string
  EventRisk: {
    CompromisedCredentialsDetected: boolean
    RiskDecision: string
    RiskLevel: string
  }
  EventType: string
}

export type UpdateState = {
  updated: any[]
  current: any[]
}

/*export enum AUDIT_AUTHOR_TYPES {
  TRIALSTAFF = 'TrialStaff',
  ADMIN = 'Admin',
  CLINICIAN = 'Clinician',
}*/
export enum AUDIT_TYPES {
  CREATE_CLINICIAN = 'CREATE_CLINICIAN',
  CREATE_TRIALSTAFF = 'CREATE_TRIALSTAFF',
  UPDATE_CLINICIAN = 'UPDATE_CLINICIAN',
  UPDATE_CLINICIAN_PII = 'UPDATE_CLINICIAN_PII',
  UPDATE_CLINICIAN_SITE_ACCESS = 'UPDATE_CLINICIAN_SITE_ACCESS',
  UPDATE_CLINICIAN_PROJECT_ACCESS = 'UPDATE_CLINICIAN_PROJECT_ACCESS',
  RETIRE_CLINICIAN = 'RETIRE_CLINICIAN',
  REACTIVATE_CLINICIAN = 'REACTIVATE_CLINICIAN',
  UPDATE_TRIALSTAFF = 'UPDATE_TRIALSTAFF',
  UPDATE_TRIALSTAFF_PII = 'UPDATE_TRIALSTAFF_PII',
  RETIRE_TRIALSTAFF = 'RETIRE_TRIALSTAFF',
  REACTIVATE_TRIALSTAFF = 'REACTIVATE_TRIALSTAFF',
  CREATE_PHASE = 'CREATE_PHASE',
  UPDATE_PHASE = 'UPDATE_PHASE',
  CREATE_SUBJECT = 'CREATE_SUBJECT',
  UPDATE_SUBJECT = 'UPDATE_SUBJECT',
  REQUEST_SUBJECT_PII = 'REQUEST_SUBJECT_PII',
  CREATE_SITE = 'CREATE_SITE',
  UPDATE_SITE = 'UPDATE_SITE',
  ACTIVATE_PROTOCOL = 'ACTIVATE_PROTOCOL',
  RETIRE_PROTOCOL = 'RETIRE_PROTOCOL',
  CLONE_PROTOCOL = 'CLONE_PROTOCOL',
  ACTIVATE_PROJECT = 'ACTIVATE_PROJECT',
  RETIRE_PROJECT = 'RETIRE_PROJECT',
  ARCHIVE_PROJECT = 'ARCHIVE_PROJECT',
  CREATE_PROJECT = 'CREATE_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  ADMIN_LOGOUT = 'ADMIN_LOGOUT',
  INVITE = 'INVITE',
  REINVITE = 'REINVITE',
}
/*export const PROTOCOL_JUSTIFICATIONS = {
  LANGUAGE_ADD: 'Add language',
  QUESTIONNAIRE_ADD: 'Add questionnaire',
  QUESTIONNAIRE_REMOVE: 'Remove questionnaire',
  QUESTIONNAIRE_UPDATE: 'Update questionnaire',
} as const*/
// export type ProtocolJustificationTypes = keyof typeof PROTOCOL_JUSTIFICATIONS

/*export enum PROJECT_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
  ARCHIVED = 'ARCHIVED',
}*/

export enum RESOURCE_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
}

export enum QUESTIONNAIRE_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
  // ARCHIVED = 'ARCHIVED',
}

export enum PROJECT_CONDITION_ENUM {
  ECZCEMA = 'Eczcema',
  ARTHRITIS = 'Arthritis',
  OSTEOARTHRITIS = 'Osteoarthritis',
  ALZHEIMER = 'Alzheimer',
  PSORIASIS = 'Psoriasis',
  ACNE = 'Acne',
  ACTINIC_KERATOSIS = 'Actinic keratosis',
}

export enum PERSONNEL_STATUS_ENUM {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
}

export enum PERSONNEL_TYPE_ENUM {
  TRIAL_STAFF = 'TRIAL_STAFF',
  CLINICIAN = 'CLINICIAN',
}

export enum TRIAL_STAFF_TYPE_ENUM {
  CTM = 'CTM',
  CRA = 'CRA',
  DM = 'DM',
}

/*export enum TRIAL_STAFF_DISPLAY_TITLES {
  CTM = 'Clinical Trial Manager',
  CRA = 'Clinical Trial Associate',
  DM = 'Data Manager',
}*/

// this is no longer valid - should read from here questionnaireCRUD - questionnaireTypes
export enum QUESTIONNAIRE_TYPE_ENUM {
  VISIT = 'VISIT',
  PHASE = 'PHASE',
  DRAFT = 'DRAFT',
}

export enum PHASE_TYPE_ENUM {
  IN_SCREENING = 'IN_SCREENING',
  IN_STUDY = 'IN_STUDY',
}

// CRUD component state
export enum CRUD_VIEW_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW',
}

export type VIEW_TYPE = keyof typeof CRUD_VIEW_TYPE

export enum QUESTIONNAIRE_RECURRENCE_ENUM {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  NONE = 'NONE',
  CUSTOM = 'CUSTOM',
}

export enum HTTPMethod {
  UPDATE = 'UPDATE',
  PUT = 'PUT',
  POST = 'POST',
}

//
// Config objects for all existing Forms
//

const FormTypesObject = {
  PHONE: 'PHONE',
  TEXT: 'TEXT',
  TEXT_TRANSLATION: 'TEXT_TRANSLATION',
  TEXTAREA: 'TEXTAREA',
  TEXTAREA_TRANSLATION: 'TEXTAREA_TRANSLATION',
  DYNAMIC_OPTIONS: 'DYNAMIC_OPTIONS',
  DYNAMIC_OPTIONS_TRANSLATION: 'DYNAMIC_OPTIONS_TRANSLATION',
  INPUT_MULTIPLE: 'INPUT_MULTIPLE',
  NUMBER: 'NUMBER',
  SLIDER_RANGE: 'SLIDER_RANGE',
  SLIDER: 'SLIDER',
  SELECT_MULTIPLE: 'SELECT_MULTIPLE',
  TIME: 'TIME',
  DATE_RANGE: 'DATE_RANGE',
  WINDOW: 'WINDOW',
  REMINDER_TIME: 'REMINDER_TIME',
  SWITCH: 'SWITCH',
  SECTION: 'SECTION',
  SPACER: 'SPACER',
  DROPDOWN: 'DROPDOWN',
} as const

type FormTypes = keyof typeof FormTypesObject

export interface BaseFormConfigObject {
  id: string
  type: FormTypes | string
  label: string
  validation: { required: boolean | string; validate?: Validate<any, FieldValues> }
  errorMsg: string
  disabled?: boolean
  className?: string
  placeholder?: string
  postfix?: string
  defaultValue?: string
}

export interface SpacerFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.SPACER
  value: string
}

export interface SectionFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.SECTION
  value: string
}

export interface SwitchFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.SWITCH
  value: boolean
}

export interface PhoneFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.PHONE
  value: { countryCode: string; tel: number } | ''
}

export interface TextFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.TEXT
  value: string
  validation: {
    required: boolean
    validate?: Validate<any, FieldValues>
    minLength?: number
    maxLength?: number
    pattern?: RegExp
  }
}

export interface TextFormConfigObjectTranslation extends BaseFormConfigObject {
  type: typeof FormTypesObject.TEXT_TRANSLATION
  value: { [key: string]: string | number } | string | number
}

export interface TextAreaFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.TEXTAREA
  value: string
}

export interface TextAreaFormConfigObjectTranslation extends BaseFormConfigObject {
  type: typeof FormTypesObject.TEXTAREA_TRANSLATION
  value: { [key: string]: string } | string
}

export interface DynamicOptionsFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.DYNAMIC_OPTIONS
  value: { [key: string]: string }[]
  errorMsgField?: string
  errorMsgGeneral?: string
}

export interface DynamicOptionsFormConfigObjectTranslation extends BaseFormConfigObject {
  type: typeof FormTypesObject.DYNAMIC_OPTIONS_TRANSLATION
  value: { [key: string]: any }[] // FIXME - check the value. If we can be more specific
  errorMsgField?: string
  errorMsgGeneral?: string
}

export interface MultipleInputFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.INPUT_MULTIPLE
  value: string[] | number[]
}

export interface DropdownFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.DROPDOWN
  value: string | number | ''
  options?: { label: string; value: string | number }[] // TODO Check the usage of data.options and data.value in FormDropDown
}

export interface NumberFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.NUMBER
  value: number | '' // FIXME '' is used for indicating "no value" in config files. Should ideally be undefined instead
  min: number
  max: number
}

export interface SliderRangeFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.SLIDER_RANGE
  value: number | number[]
  options: number[]
  prefix?: string
}

export interface SliderFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.SLIDER
  value: number
  min: number
  max: number
  displayFactor?: number
}

export interface SelectMultipleFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.SELECT_MULTIPLE
  value: string[] | number[]
  options: { label: string; value: any; title: string; symbol: any }[]
}

export interface TimeFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.TIME
  value: { m: number; h: number } | null
  outputFormat?: 'HH:mm' | 'minutes'
}

export interface DateFormConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.DATE_RANGE
  value: {
    startAt: { y: number; M: number; D: number }
    endAt: { y: number; M: number; D: number }
    duration: number
  }
  format: string
}

export interface WindowWidgetConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.WINDOW
  value: {
    startAt: { m: number; h: number } | null
    endAt: { m: number; h: number } | null
  }
}

export interface ReminderTimeWidgetConfigObject extends BaseFormConfigObject {
  type: typeof FormTypesObject.REMINDER_TIME
  value: { m: number; h: number; duration: number } | null
}

export type FormConfigTypes =
  | SpacerFormConfigObject
  | SectionFormConfigObject
  | SwitchFormConfigObject
  | PhoneFormConfigObject
  | TextFormConfigObject
  | TextFormConfigObjectTranslation
  | TextAreaFormConfigObject
  | TextAreaFormConfigObjectTranslation
  | DynamicOptionsFormConfigObject
  | DynamicOptionsFormConfigObjectTranslation
  | MultipleInputFormConfigObject
  | DropdownFormConfigObject
  | NumberFormConfigObject
  | SliderRangeFormConfigObject
  | SliderFormConfigObject
  | SelectMultipleFormConfigObject
  | TimeFormConfigObject
  | DateFormConfigObject
  | WindowWidgetConfigObject
  | ReminderTimeWidgetConfigObject

export type Answer = {
  enrollmentId: string
  questionId: string
  value: string
}

export type Project = {
  _id: string
  active: boolean
  projectRef: string
  title: string
  condition: string
  age: [low: number, high: number]
  sites: string[]
  questionnaires: string[]
  languages: LanguageISOCode[]
  maxDistanceTravel: number
  maxDistance: number
}

export type SiteType = {
  _id: string
  title: string
  active: boolean
  address: string
  contactPerson: string
  createdAt: Date
  projectId: string
  siteNumber: number
  timeZone: string
  updatedAt: Date
}

export type LanguageISOCode = string
export type LanguageType = { name: string; isoCode: LanguageISOCode; active: boolean }

export type SupportedCountriesType = {
  abbreviation: string
  countryCode: string
  name: string
}

export type ProjectConfigType = {
  countries: SupportedCountriesType[]
  languages: { name: string; isoCode: string; active: null }[]
  timezones: { value: string; label: string }[]
  bodyParts: [key: string]
  groupTypes: [key: string]
  questionnaireRecurrence: [key: string]
  questionTypes: QuestionValueLabelType[]
}

export type Questionnaire = {
  _id: string
  projectId: string
  active: boolean
  start: string
  end: string
  name: string
  questions: Question[]
  createdOn: Date
  lastModified: Date
}

export type UnitSet = 'imperial' | 'metric'

export enum QuestionsTypes {
  radio = 'radio',
  checkbox = 'checkbox',
  text = 'text',
  number = 'number',
  info = 'info',
  bmi = 'bmi',
  phonenumber = 'phonenumber',
  name = 'name',
  gender = 'gender',
  birthdate = 'birthdate',
  nprs5 = 'nprs5',
  nprs11pt5l = 'nprs11pt5l',
  nprs11pt2l = 'nprs11pt2l',
  yesno = 'yesno',
}

export type QuestionType = keyof typeof QuestionsTypes

export type QuestionValueLabelType = { value: QuestionType; label: string }

export interface Question {
  _id: string
  questionnaireId: string
  title: Translation
  body: Translation
  continuation: Translation
  type: QuestionType
  // lastModified: Date
  next: (string | null)[]
  // storage: string
  required: boolean
  externalStorageId?: string
}

export interface RadioQuestion extends Question {
  options: Translation[]
  includingOptions: number[]
  excludingOptions: number[]
}

export interface CheckboxQuestion extends Question {
  options: Translation[]
  includingOptions: number[]
  excludingOptions: number[]
}

export interface PhoneNumberQuestion extends Question {}

export interface NameQuestion extends Question {
  placeholder: Translation
  minLength: number
  maxLength: number
}

export interface TextQuestion extends Question {
  placeholder: Translation
  minLength: number
  maxLength: number
}

export interface GenderQuestion extends Question {
  options: Translation[]
  includingOptions: number[]
  excludingOptions: number[]
}

export interface BirthdateQuestion extends Question {
  includingAgeRanges: { from: number; to: number }[]
  excludingAgeRanges: { from: number; to: number }[]
}

export interface NumberQuestion extends Question {
  min: number
  max: number
  includingRanges: { from: number; to: number }[]
  excludingRanges: { from: number; to: number }[]
}

export interface InfoQuestion extends Question {
  image: string
}

export interface BmiQuestion extends Question {
  defaultDisplayUnit: UnitSet
  weightHeader: Translation
  heightHeader: Translation
  imperialUnitText: Translation
  metricUnitText: Translation
  includingRanges: { from: number; to: number }[]
  excludingRanges: { from: number; to: number }[]
}

export type Nprs11OptionType = {
  classification?: Translation
  intensity: Translation[]
}
export interface Nprs11Question extends Question {
  label: string
  options: Nprs11OptionType[]
}

export interface Nprs5Question extends Question {
  field: string
  label: string
  options: string[]
}

export type Phase = {
  _id: string
  originalId: string
  projectId: string
  title: string
  duration: number
  stableId: string
  type: PHASE_TYPE_ENUM
  order: number
  groups: any[]
  bodyParts: string[]
  status: RESOURCE_STATUS
  shortCompliance: number
  overallCompliance: number
}

export type Translation = { [key: string]: string }
