/**
 * Created by @author @Søren Tramm on 08/08/2023.
 * @Description Adjusted copy of FormTextField
 */

import { Input } from 'antd'
import React, { useEffect } from 'react'
import { FormLabel } from './FormLabel'
import { get, UseFormReturn } from 'react-hook-form'
import { LanguageISOCode, LanguageType, TextAreaFormConfigObjectTranslation } from '../../Types'
import { LANGUAGE_LABELS } from '../../constants'

type Props = {
  formHook: UseFormReturn
  data: TextAreaFormConfigObjectTranslation
  languages?: LanguageType[] | undefined
  currentLanguage?: LanguageISOCode
}

const { TextArea } = Input

export const FormTextAreaTranslation = ({ data, formHook, languages, currentLanguage }: Props) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = formHook

  const getDefaultValue = (
    dataValue: string | number | { [key: string]: string | number },
    languages: LanguageType[] = []
  ) => {
    // Return the value untouched if value is an object
    if (typeof dataValue === 'object') {
      return dataValue
    }
    // Build default value for all languages
    // Prefix only (non-empty) string values with the isoCode
    return languages.reduce(
      (acc, { isoCode }) => ({
        ...acc,
        [isoCode]: dataValue === '' ? '' : `${isoCode.toUpperCase()} - ${dataValue}`,
      }),
      {} as { [key: string]: string }
    )
  }

  const getCurrentValue = (
    formHookValue: { [key: string]: string | number } | undefined,
    dataValue: string | number | { [key: string]: string | number } | undefined,
    isoCode: string
  ): string => {
    // Check if getValues(data.id) exists
    if (formHookValue) {
      return String(formHookValue[isoCode])
    }

    // Return an empty string if data.value is undefined, null, or empty string
    if (dataValue === undefined || dataValue === null || dataValue === '') {
      return ''
    }

    // Return value based on language if value is an object. Convert to string if value is a number
    if (typeof dataValue === 'object') {
      return String(dataValue[isoCode])
    }

    // Prefix with isoCode. Will only happen if value is a string
    return `${isoCode.toUpperCase()} - ${dataValue}`
  }

  /*
  Error handling
  */
  type ErrorState = {
    [key: string]: { message: string; type: string; ref: { name: string; value: string } }
  }
  const errorState = get(errors, data.id)
  const compileErrorMessage = (errorState: ErrorState, dataErrMsg: string): string => {
    const message = errorState?.message || dataErrMsg
    return `${message}: ${Object.keys(errorState)
      .map((key) => LANGUAGE_LABELS[key])
      .join(', ')}`
  }

  useEffect(() => {
    // List pf field ids, by enabled language
    const fields = languages?.map((lang) => `${data.id}.${lang.isoCode}`) || []

    // Register fields
    fields.forEach((field) => {
      register(field, { ...data.validation })
    })
  }, [register, languages, data.id, data.validation])

  useEffect(() => {
    // Unregister fields when the component unmounts
    const fields = languages?.map((lang) => `${data.id}.${lang.isoCode}`) || []
    return () => unregister(fields)
  }, [unregister, data.id, languages])

  /**
   * Build the default values for all languages from the config value
   * Set the default values in the formhook as one object covering all languages
   */
  useEffect(() => {
    if (data.value !== undefined && data.value !== null) {
      // Build default value
      const value = getDefaultValue(data.value, languages)
      setValue(data.id, value)
    }
  }, [data.id, data.value, setValue, languages])

  const onChange = (e) => {
    const val = e?.target?.value || ''

    // Not sure why this guard is necessary, but the original code had it
    if (!currentLanguage) {
      return
    }

    const id = `${data.id}.${currentLanguage}`
    setValue(id, val)
  }

  // Getting the value for rendering in the react field element
  const currentValue = getCurrentValue(getValues(data.id), data.value, currentLanguage!)

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <TextArea
        key={data.id + '-' + currentLanguage}
        defaultValue={currentValue}
        //onChange={currentLanguage && ((e) => onChange(e.target.value, currentLanguage))}
        onChange={onChange}
        placeholder={currentLanguage ? currentLanguage.toUpperCase() + ' - ' + data.placeholder : ''}
        autoSize={{ minRows: 4, maxRows: 4 }}
        style={{ width: '100%' }}
      />
      {errorState ? <div className="p-small text-danger">{compileErrorMessage(errorState, data.errorMsg)}</div> : null}
    </div>
  )
}
