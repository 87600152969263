/**
 * Created by @author Søren Tramm on 15/08/2023.
 */
export const radioQuestionConfig = [
  {
    id: 'options',
    type: 'DYNAMIC_OPTIONS_TRANSLATION',
    placeholder: 'Fill in option here',
    label: 'Options',
    postfix: '',
    value: [],
    validation: { required: false },
    errorMsg: '',
  },
] as const
