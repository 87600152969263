import { UseFormReturn } from 'react-hook-form'
import { DynamicOptionsFormConfigObjectTranslation, LanguageISOCode } from '../../Types'
import { Button, Input } from 'antd'
import { useEffect, useState } from 'react'
import { FormLabel } from './FormLabel'
import { LANGUAGE_LABELS } from '../../constants'

/**
 * Created by @author @Søren Tramm on 08/08/2023.
 * @Description Create a list of options. Includes functionality for adding/removing options
 */
type Props = {
  formHook: UseFormReturn
  data: DynamicOptionsFormConfigObjectTranslation
  languages: any[] | undefined
  currentLanguage?: LanguageISOCode | undefined
}

export const FormDynamicOptionsTranslation = ({ data, formHook, languages, currentLanguage }: Props) => {
  const {
    unregister,
    register,
    setValue: parentSetValue,
    formState: { errors },
  } = formHook

  const [options, setOptions] = useState(data.value)
  const [errorMessage, setErrorMessage] = useState('')
  const [showGeneralError, setShowGeneralError] = useState(false)

  useEffect(() => {
    if (!!options) {
      // Register all already existing options
      options.forEach((option, index) => {
        languages?.forEach((lang) => {
          const id = createFieldId(data.id, index, lang.isoCode)
          register(id, { ...data.validation })
          parentSetValue(id, option[lang.isoCode])
        })
      })
    }

    // Register the data id which is the main property name for the field containing all options
    // We use this registration for error handling only. By doing that formHook handles error check.
    register(data.id, { ...data.validation })
  }, [options, register, data.id, data.validation, languages, parentSetValue])

  const errorState = errors[data.id]
  useEffect(() => {
    /*
    If the error state ref is set and the name-property of ref is the data.id, we assume (yes I know) that the error is a error indicating taht no optins ahs been added
    Hence we will generate a separate error message for that case
    */
    if (!!errorState) {
      if (errorState.ref && errorState.ref['name'].includes(data.id)) {
        // If the error state ref is set directly on the error state it is an error pn general level
        // Meaning that no options have been added
        setErrorMessage(errorState.message ? (errorState.message as string) : (data.errorMsgGeneral as string))
        setShowGeneralError(true)
      } else {
        // If the error state ref is NOT set directly on the error state there are an array of errors
        // Meaning that there are errors related to specific input fields.
        setErrorMessage(errorState.message ? (errorState.message as string) : (data.errorMsgField as string))
      }
    }
  }, [errors, errorState, data.errorMsgField, data.errorMsgGeneral, data.id])

  const createFieldId = (id: string, index: number, lang: string): string => {
    return `${id}.${index}.${lang}`
  }
  const addOption = () => {
    setShowGeneralError(false)

    // Add an empty object to the list of options
    // The options array is used for rendering field components
    const newOption = {}
    languages?.forEach((lang) => {
      newOption[lang.isoCode] = ''
    })
    const updatedOptions = [...options, newOption]
    setOptions(updatedOptions)

    // Register the new option field
    const index = updatedOptions.length - 1
    languages?.forEach((lang) => {
      const id = createFieldId(data.id, index, lang.isoCode)
      register(id)
    })
  }

  const removeOption = (index: number) => {
    setShowGeneralError(false)

    let oldfieldIdList: string[] = []
    options.forEach((option, index) => {
      Object.keys(option).forEach((key) => {
        const id = createFieldId(data.id, index, key)
        oldfieldIdList.push(id)
      })
    })
    unregister(oldfieldIdList)

    const updatedOptions = options.filter((_, i) => i !== index)
    setOptions(updatedOptions)

    updatedOptions.forEach((option, index) => {
      Object.keys(option).forEach((key) => {
        const id = createFieldId(data.id, index, key)
        register(id)
      })
    })
  }

  const changeHandler = (value: string, index: number, lang: string) => {
    const id = createFieldId(data.id, index, lang)
    parentSetValue(id, value)

    const updatedOptions = [...options]
    updatedOptions[index][lang] = value
    setOptions(updatedOptions)
  }

  /**
   * Compiles an error for a particular field
   * @param index
   * @param state
   * @param message
   */
  const compileErrorMessage = (index, state, message) => {
    const langList = Object.keys(state[index])
      .map((key) => LANGUAGE_LABELS[key])
      .join(', ')
    return `${message}: ${langList}`
  }

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      {showGeneralError ? (
        <div className="mt-1 row">
          <div className="col-11 p-small text-danger">{errorMessage}</div>
        </div>
      ) : null}

      {options &&
        options.map((option, index) => (
          <div key={index} className="mt-1 row">
            <div className="col-11">
              {languages?.map((lang) => (
                <Input
                  key={`${option.id}.${lang.isoCode}`}
                  placeholder={lang.isoCode.toUpperCase() + ' - ' + data.placeholder}
                  value={option[lang.isoCode]}
                  status={errorState && errorState[index] ? 'error' : ''}
                  onChange={(e) => changeHandler(e.target.value, index, lang.isoCode)}
                  className={`${lang.isoCode !== currentLanguage && 'd-none'}`}
                />
              ))}
              {errorState && errorState[index] ? (
                <div className="p-small text-danger">{compileErrorMessage(index, errorState, errorMessage)}</div>
              ) : null}
            </div>
            <div className="col-1">
              <Button shape="circle" onClick={() => removeOption(index)}>
                -
              </Button>
            </div>
          </div>
        ))}
      <div className="mt-3 row">
        <div className="col-12">
          <Button shape="circle" onClick={addOption}>
            +
          </Button>
        </div>
      </div>
    </div>
  )
}
